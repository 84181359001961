<template>
  <motion.li
    class="v-color-mono-800 v-d-flex v-justify-content-between v-cart-summary-rhudaur-single"
    :key="`cart-summary-${uid}`"
    :initial="{ opacity: 0, scaleY: 0 }"
    :animate="{ opacity: 1, scaleY: 1 }"
    :exit="{ opacity: 0, scaleY: 0 }"
    :transition="{
      duration: 0.4
    }"
    layout
  >
    <div class="v-d-flex v-align-items-center">
      <span
        :class="`v-typography-text-${inSecondStage ? 4 : 5}`"
        v-html="text"
      />
      <common-popover
        v-if="tooltipText"
        class="v-ml-xxs"
        placement="top-start"
      >
        <template #content>
          {{ tooltipText }}
        </template>
      </common-popover>
    </div>
    <div class="v-d-flex v-align-items-center">
      <span
        v-if="type === 'points-accrual'"
        :class="`v-typography-text-${inSecondStage ? 4 : 5} v-cart-summary-rhudaur-single__currency--${type}`"
        >+</span
      >
      <template v-if="amountModified > 0 && amountModified !== amount">
        <common-currency
          :amount="amountModified"
          :class="`v-typography-text-${inSecondStage ? 4 : 5} v-cart-summary-rhudaur-single__currency--${type}`"
        />
        <common-currency
          strikethrough
          :amount="amount"
          :class="`v-typography-text-${inSecondStage ? 4 : 5} v-color-mono-600 v-cart-summary-rhudaur-single__currency--${type}`"
        />
      </template>
      <common-currency
        :amount="amount"
        :class="`v-typography-text-${inSecondStage ? 4 : 5} v-cart-summary-rhudaur-single__currency--${type}`"
      />
    </div>
  </motion.li>
</template>

<script setup lang="ts">
import { motion } from 'motion-v'

const {
  amount = 0,
  amountModified = -1,
  text = '',
  type = 'default'
} = defineProps<{
  inSecondStage: boolean
  amount?: number
  amountModified?: number
  text?: string
  tooltipText?: string
  type?: 'default' | 'points-accrual' | 'points-withdraw'
  sign?: string
}>()

const uid = useId()
</script>

<style lang="scss">
@use 'assets/variables';

.v-cart-summary-rhudaur-single {
  &__currency {
    &--points-accrual {
      color: variables.$SuccessColor;
      font-weight: 600;
    }
    &--points-withdraw {
      color: variables.$ErrorColor;
      font-weight: 600;
    }
  }
}
</style>
